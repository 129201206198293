@layer components {
  ui-file-dropper {
    @apply block relative overflow-hidden rounded-lg cursor-pointer;

    label {
      @apply block mb-1 text-sm font-semibold;
    }

    input[type="file"] {
      @apply absolute -left-px right-0 w-px h-px opacity-0;
    }

    .drop-zone {
      @apply border border-dashed border-border rounded-lg pointer-events-none transition;
      @apply px-4 py-8 flex items-center justify-center text-text-muted;
    }

    &:hover,
    &:focus,
    &:focus-visible,
    &:focus-within,
    &.drag-active {
      .drop-zone {
        @apply border-focus text-focus bg-focus/5;
      }
    }

    &:focus-visible,
    &:has(:focus-visible) {
      @apply outline outline-2 outline-offset-2 outline-focus;
    }
  }
}
