@layer components {
  #header-primary {
    @apply bg-bg/90 backdrop-blur border-t-4 border-t-accent border-b border-b-border sticky top-0 z-30;

    .nav-grid {
      @apply flex items-center gap-x-8 py-2 px-4;

      > .brand {
        @apply flex-[24rem] flex-grow-0 flex;
      }

      > .product-search {
        @apply flex-1;
      }

      > .user-menu {
        @apply flex-[24rem] flex-grow-0;
      }

      /* mobile overrides */
      @screen max-lg {
        @apply flex-wrap;

        > .brand {
          @apply order-1 flex-auto;
        }

        > .product-search {
          @apply order-3 flex-[100%] p-4;
        }

        > .user-menu {
          @apply order-2 flex-auto;
        }
      }
    }

    /* navbar search */
    #product-search__container {
      @apply rounded-lg;

      .dropdown-menu {
        @apply mt-2 p-0 bg-bg backdrop-blur overflow-hidden;

        .dropdown-item {
          @apply py-1 px-4 text-sm text-ellipsis overflow-hidden;

          &:hover,
          &:focus {
            @apply bg-accent/5;
          }
        }

        li.active .dropdown-item {
          @apply bg-accent/90 text-bg;
        }
      }
    }

    /* section styles */
    .brand {
      a.home {
        @apply inline-block p-2 rounded-full transition;
        @apply outline outline-1 outline-offset-4 outline-transparent;

        &:hover,
        &:focus {
          @apply bg-accent/10;
        }

        &:focus-visible {
          @apply outline outline-2 outline-offset-2 outline-focus;
        }

        .logo {
          @apply w-8 h-8;
        }
      }
    }

    ul.user-menu {
      @apply flex items-center justify-end;

      .menu-item {
        @apply block rounded-full p-2 transition;
        @apply outline outline-1 outline-offset-4 outline-transparent;

        .icon {
          @apply w-6 h-6;
        }

        &:hover,
        &:focus {
          @apply bg-accent/10;
        }

        &:focus-visible {
          @apply outline outline-2 outline-offset-2 outline-focus;
        }
      }

      .dropdown {
        @apply relative;
      }

      .dropdown-toggle {
        @apply ml-4 py-1 px-2 flex gap-2 items-center rounded transition;
        @apply outline outline-1 outline-offset-4 outline-transparent;
        @apply font-medium;

        .icon {
          @apply w-4 h-4;
        }

        &:hover,
        &:focus {
          @apply text-accent;
        }

        &:focus-visible {
          @apply outline outline-2 outline-offset-2 outline-focus;
        }
      }

      .dropdown-menu {
        @apply p-0 py-4 mt-2 left-auto right-0;
      }

      .dropdown-item {
        @apply flex gap-2 items-center py-2 px-4 w-48 transition;
        @apply outline outline-1 outline-offset-4 outline-transparent;

        &:hover,
        &:focus {
          @apply bg-accent/10 text-accent;
        }

        &:focus-visible {
          @apply outline outline-2 outline-offset-2 outline-focus;
        }
      }
    }
  }
}
