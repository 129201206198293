@layer components {
  .metric-cards {
    @apply grid gap-4 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3;
  }

  .metric-card {
    @apply block border border-border rounded-lg transition;

    .card-header {
      @apply py-3 px-4 bg-bg-muted/50 text-lg font-medium border-b border-border rounded-t-lg transition;
      @apply flex gap-2 items-center justify-between;

      .icon {
        @apply bg-bg border border-border rounded-full;

        svg {
          @apply opacity-0;
        }
      }
    }

    .card-body {
      @apply py-3 px-4 flex gap-2 flex-wrap items-center justify-between;

      .card-metric {
        @apply text-xl font-semibold;
      }

      .card-metric-text {
        @apply text-sm text-text/50;
      }
    }

    &.selected-metric {
      @apply border-accent;

      .card-header {
        @apply text-accent-dark bg-accent/5 border-b-accent;

        .icon {
          @apply text-accent border-accent;

          svg {
            @apply opacity-100;

            path {
              stroke-width: 3;
            }
          }
        }
      }
    }
  }

  button.metric-card,
  a.metric-card {
    @apply cursor-pointer;

    &:hover,
    &:focus {
      @apply border-accent/50;

      .card-header {
        @apply text-accent-dark bg-accent/10 border-b-accent/50;

        .icon {
          @apply border-accent/50;
        }
      }
    }

    &.selected-metric {
      @apply shadow-sm shadow-accent/10;

      &:hover,
      &:focus {
        @apply border-accent shadow-xl shadow-accent/10;

        .card-header {
          @apply border-b-accent;

          .icon {
            @apply border-accent;
          }
        }
      }
    }
  }
}
