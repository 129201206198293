@layer components {
  .toggle-buttons-collapse {
    @apply flex-col items-stretch;

    .toggle-button {
      @apply ml-0 -mt-px;

      &:first-child {
        @apply rounded-l-none rounded-t;
      }

      &:last-child {
        @apply rounded-r-none rounded-b;
      }
    }
  }

  .toggle-buttons {
    @apply flex flex-row items-center w-max;
    @apply max-sm:toggle-buttons-collapse;
  }

  .toggle-button {
    @apply flex gap-3 items-center -ml-px;
    @apply py-2 px-4 leading-6 bg-button-text text-button-bg font-medium cursor-pointer transition;
    @apply ring-1 ring-button-bg ring-inset;
    @apply outline outline-1 outline-offset-4 outline-transparent;

    &:hover,
    &:focus {
      @apply bg-button-bg/5 z-10;
    }

    &.active {
      @apply bg-button-bg text-button-text;
    }

    &:focus-visible {
      @apply outline outline-2 outline-offset-2 outline-focus;
    }

    &:disabled {
      @apply filter grayscale opacity-75 pointer-events-none;
    }

    &:first-child {
      @apply rounded-l;
    }

    &:last-child {
      @apply rounded-r;
    }
  }

  .toggle-buttons.secondary .toggle-button {
    @apply bg-button-secondary-text text-button-secondary-bg;
    @apply ring-button-secondary-bg;

    &:hover,
    &:focus {
      @apply bg-button-secondary-bg/5;
    }

    &.active {
      @apply bg-button-secondary-bg text-button-secondary-text;
    }
  }
}
