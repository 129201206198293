@layer components {
  .report-pdf-root {
    --pdf-margin-y: 0.5in;
    --pdf-margin-x: 0.5in;
  }

  .px-pdf {
    @apply px-[--pdf-margin-x];
  }

  .py-pdf {
    @apply py-[--pdf-margin-y];
  }

  .-mx-pdf {
    @apply -mx-[--pdf-margin-x];
  }
}
