@layer components {
  form:invalid input[type="submit"],
  form:invalid button[type="submit"] {
    @apply filter grayscale opacity-75 pointer-events-none;
  }

  .form-control {
    @apply block relative;

    label {
      @apply block mb-1 text-sm font-semibold;
    }

    &:has(input:disabled) label,
    &:has(select:disabled) label {
      @apply text-text-muted;
    }

    /* standard box-type form controls */
    input[type="text"],
    input[type="password"],
    input[type="number"],
    input[type="datetime-local"],
    input[type="email"],
    input[type="search"],
    input[type="tel"],
    input[type="url"],
    input[type="file"],
    select,
    textarea {
      @apply block w-full py-2 px-4 leading-6 bg-bg border-none rounded appearance-none transition;
      @apply outline outline-1 outline-offset-4 outline-transparent ring-1 ring-border ring-inset;

      &:disabled {
        @apply bg-bg-muted;
      }

      &:hover:not(:disabled) {
      }

      &:focus:not(:disabled) {
        @apply outline outline-2 outline-offset-2 outline-focus;
      }

      &::placeholder {
        @apply text-text opacity-50;
      }
    }

    input[type="number"].hide-spinner {
      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        @apply appearance-none m-0;
      }
    }

    /* floating right icon */
    .icon {
      @apply absolute right-3 bottom-2.5 w-5 h-5 opacity-50 pointer-events-none;
    }

    /* caret for select dropdowns */
    &.dropdown {
      input {
        @apply pr-8;
      }

      &::after {
        content: "";
        @apply absolute bottom-2 right-2 w-6 h-6 pointer-events-none;
        @apply bg-center bg-contain;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjkiIGhlaWdodD0iMjkiIHZpZXdCb3g9IjAgMCAyOSAyOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE5LjUgMTIuNUwxNC41IDE3LjVMOS41IDEyLjUiIHN0cm9rZT0iYmxhY2siIHN0cm9rZS13aWR0aD0iMiIvPgo8L3N2Zz4K);
      }
    }
  }

  /* checkboxes & radio groups */
  .checkbox-group,
  .radio-group {
    @apply flex flex-wrap flex-col gap-x-4 gap-y-2;

    &.inline {
      @apply flex flex-row;
    }

    .group-label {
      @apply w-full;
    }
  }

  .form-control.checkbox,
  .form-control.radio {
    @apply relative flex-initial flex items-start gap-3 cursor-pointer;

    input[type="checkbox"],
    input[type="radio"] {
      @apply mt-0.5 w-4 h-4 flex-shrink-0 flex-grow-0;

      &:focus-visible {
        @apply outline outline-2 outline-offset-2 outline-focus;
      }
    }

    /* override default input checkbox/radio styles if supported */
    @supports selector(:has(input)) {
      input[type="checkbox"],
      input[type="radio"] {
        @apply mt-0 absolute w-px h-px overflow-hidden opacity-[0.01] -z-10;
      }

      &::before {
        content: "";
        @apply -mt-0.5 w-6 h-6 flex-shrink-0 flex-grow-0 rounded transition;
        @apply outline outline-1 outline-offset-4 outline-transparent ring-1 ring-border ring-inset;
      }

      &:hover::before {
        @apply bg-border/10;
      }

      &:has(input:checked)::before {
        @apply bg-accent ring-accent;
      }

      &:has(input:indeterminate)::before {
        @apply bg-border ring-border;
      }

      &:has(input:focus-visible)::before {
        @apply outline outline-2 outline-offset-2 outline-focus;
      }

      &:has(input:disabled) {
        @apply text-text-muted pointer-events-none;

        &::before {
          @apply border-border/50;
        }
      }

      &.checkbox {
        &::after {
          content: "";
          @apply block absolute border-r-2 border-b-2 border-bg transition-all ease-out-back duration-300;
          @apply w-1.5 h-3 opacity-0 transform rotate-[30deg] translate-x-[0.5625rem] translate-y-[0.15rem] scale-x-[67%] scale-y-[33%];
        }

        &:has(input:checked)::after {
          @apply opacity-100 rotate-45 scale-100;
        }

        &:has(input:indeterminate)::after {
          @apply opacity-100 border-b-0 rotate-90 scale-100;
        }
      }

      &.radio {
        &::before {
          @apply rounded-full;
        }

        &::after {
          content: "";
          @apply block absolute w-2 h-2 bg-bg rounded-full transition-all ease-out-quint duration-500;
          @apply opacity-0 transform scale-0 translate-x-2 translate-y-1.5;
        }

        &:has(input:checked)::after {
          @apply opacity-100 scale-100;
        }
      }
    }
  }

  /* color pickers */
  .form-control.color {
    input[type="color"] {
      @apply block w-10 h-10 appearance-none bg-none bg-transparent border-none cursor-pointer rounded-full transition;
      @apply outline outline-1 outline-offset-4 outline-transparent ring-1 ring-border ring-inset;

      &:focus:not(:disabled) {
        @apply outline outline-2 outline-offset-2 outline-focus;
      }

      &::-webkit-color-swatch-wrapper {
        @apply p-0;
      }

      &::-webkit-color-swatch {
        @apply rounded-full border-none;
      }
    }
  }

  /* inline form error styles */
  .form-control.invalid {
    label,
    input::placeholder,
    textarea::placeholder,
    .icon {
      @apply text-status-critical;
    }

    input,
    select,
    textarea {
      @apply ring-status-critical;
    }
  }

  .checkbox-group.invalid,
  .radio-group.invalid {
    label,
    .group-label {
      @apply text-status-critical;
    }
  }

  .form-control.checkbox.invalid,
  .form-control.radio.invalid {
    @apply text-status-critical;
  }
}
