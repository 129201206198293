/*
 * This stylesheet provides legacy support for old bootstrap datepickers.
 * Since the layout works roughly as-is, this file only contains style customizations
 * and minor tweaks.
 */

.datepicker {
  @apply p-0 w-56;

  table {
    @apply w-full;

    thead {
      @apply text-sm bg-bg-muted border-b border-border;

      tr {
        @apply bg-bg border-b border-border;

        th {
          @apply text-sm/5 h-7 font-semibold;

          &.prev,
          &.next,
          &.datepicker-switch {
            @apply hover:bg-focus/10 cursor-pointer;
          }

          &.dow {
            @apply text-xs/4 text-text/50;
          }
        }
      }
    }

    td.day {
      @apply w-8 text-sm/5 h-7 text-center rounded;

      &.old {
        @apply text-text/25;
      }

      &.disabled {
        @apply text-text/50 line-through cursor-not-allowed;
      }

      &:not(.active):not(.disabled) {
        @apply hover:bg-focus/10 cursor-pointer;
      }

      &.today {
        @apply text-focus bg-focus/10;
      }

      &.active {
        @apply text-bg bg-focus;
      }
    }

    tfoot {
      tr {
        @apply bg-bg-muted border-t border-border;
      }

      th {
        @apply text-sm/5 h-7 font-semibold;

        &.clear {
          @apply hover:bg-focus/10 cursor-pointer;
        }
      }
    }
  }

  .datepicker-months,
  .datepicker-years,
  .datepicker-decades,
  .datepicker-centuries {
    table tbody tr td[colspan="7"] {
      .month,
      .year,
      .decade,
      .century {
        @apply block float-left py-2 text-sm text-center hover:bg-focus/10 rounded cursor-pointer;
        width: 25%;

        &.disabled {
          @apply text-text/50 line-through cursor-not-allowed;
        }

        &:not(.active):not(.disabled) {
          @apply hover:bg-focus/10 cursor-pointer;
        }

        &.focused {
          @apply text-focus bg-focus/10;
        }

        &.active {
          @apply text-bg bg-focus;
        }
      }
    }
  }
}
