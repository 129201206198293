@layer components {
  .metric-change {
    @apply inline-block px-1.5 rounded font-semibold bg-text/5 text-text/25 text-sm;
    @apply ordinal slashed-zero tabular-nums;

    &.good {
      @apply bg-status-success/5 text-status-success;
    }

    &.bad {
      @apply bg-status-critical/5 text-status-critical;
    }
  }
}
