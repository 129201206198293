@layer components {
  ui-searchable {
    [data-search-truncation]:not(.visible),
    [data-search-no-results]:not(.visible) {
      display: none !important;
    }

    ui-searchable-items {
      @apply transition;
    }

    &[loading] ui-searchable-items {
      @apply opacity-50;
    }

    ui-searchable-item {
      @apply block;
    }
  }
}
