/*
 * These styles are used to add drop-in support for the old & bulky DataTables across the app.
 * Since DataTables manipulates DOM elements directly in a very specific way, using very specific
 * HTML classes, we have no choice but to style for these generated classes.
 *
 * Unfortunately, dropping DataTables would mean that we won't be able to reuse most of this
 * stylesheet in the future.
 */

.dataTables_wrapper {
  @apply panel overflow-x-auto;
}

.dataTables_header {
  @apply relative panel-header flex flex-wrap items-center gap-4 bg-bg-muted border-b border-border;
}

.dataTables_footer {
  @apply panel-footer border-t border-border;
}

.dataTables_info {
  @apply relative panel-header -mt-3 pt-0 text-xs text-text/50 font-semibold;
}

.dataTables_length {
  @apply block text-base leading-6 text-text/50;

  label {
    @apply flex flex-wrap gap-2;
  }

  select {
    @apply bg-bg font-semibold border border-border rounded;
    @apply outline outline-1 outline-offset-4 outline-transparent;

    &:focus {
      @apply outline outline-2 outline-offset-2 outline-focus;
    }
  }
}

.dataTables_filter {
  @apply flex-1;

  label {
    @apply form-control;
  }
}

.dt-buttons {
  @apply button-group;
}

.dt-buttons .dt-button {
  @apply button;

  > span {
    @apply flex gap-3 items-center;
  }
}

.dt-button-collection {
  @apply absolute hidden z-20 my-2 py-2 min-w-48 bg-bg rounded border border-border shadow-xl;
}

.dt-button-collection .dt-button {
  @apply relative flex gap-3 items-center;
  @apply py-1 px-4 pr-12 leading-6 bg-bg text-text font-medium cursor-pointer transition-all;
  @apply outline outline-1 outline-offset-4 outline-transparent;
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 13.1429L10.6 17L19 8' stroke-width='2' stroke='#00ada7' stroke-opacity='0.5'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center right 1rem;
  background-size: 0 0;

  &:hover,
  &:focus {
    @apply bg-button-bg/5 text-button-bg;
  }

  &:focus-visible {
    @apply outline outline-2 outline-offset-2 outline-focus;
  }

  &:disabled {
    @apply filter grayscale opacity-75 pointer-events-none;
  }

  &.active {
    @apply text-button-bg;
    background-position: center right 0.5rem;
    background-size: 1.25rem 1.25rem;
  }
}

.dt-button-background {
  @apply fixed top-0 left-0 w-full h-full z-10;
}

table.dataTable {
  @apply min-w-full;

  &.table-clickable {
    tbody tr {
      @apply cursor-pointer;
    }

    tbody tr:hover,
    tbody tr:focus {
      @apply bg-focus/5;
    }
  }

  /* apply default striping & hover styles */

  tbody tr:nth-child(2n) {
    @apply bg-bg-muted/50;
  }

  tbody tr:hover,
  tbody tr:focus {
    @apply bg-focus/5;
  }

  /* apply default border colors everywhere */
  thead,
  tbody,
  tr,
  td,
  th {
    @apply border-border transition;
  }

  th,
  td {
    @apply text-left py-3 px-4;
  }

  th.sorting,
  th.sorting_asc,
  th.sorting_desc {
    @apply pr-10;
  }

  th:last-child.border-r,
  td:last-child.border-r {
    @apply !border-r-0;
  }

  thead {
    @apply bg-bg-muted border-b;

    .sorting,
    .sorting_asc,
    .sorting_desc,
    .sorting_asc_disabled,
    .sorting_desc_disabled {
      @apply cursor-pointer bg-no-repeat;
      background-position: right 0.67rem bottom 0.67rem;
    }

    .sorting {
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16 15L12 19L8 15' stroke='black' stroke-opacity='0.25' stroke-linecap='square'/%3E%3Cpath d='M8 9L12 5L16 9' stroke='black' stroke-opacity='0.25' stroke-linecap='square'/%3E%3C/svg%3E");
    }

    .sorting_asc,
    .sorting_asc_disabled {
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 13L12 9L16 13' stroke='black' stroke-opacity='0.75' stroke-linecap='square'/%3E%3C/svg%3E");
    }

    .sorting_desc,
    .sorting_desc_disabled {
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16 11L12 15L8 11' stroke='black' stroke-opacity='0.75' stroke-linecap='square'/%3E%3C/svg%3E");
    }
  }

  thead th {
    @apply align-bottom font-semibold text-text/50;

    strong {
      @apply font-semibold text-text;
    }
  }

  tbody {
    @apply relative bg-bg;
  }

  tbody td {
    @apply align-top;
  }

  tbody + tbody tr:first-child {
    @apply border-t;
  }

  tbody tr:not(:last-child) {
    @apply border-b border-border/50;
  }
}

.dataTables_empty {
  @apply italic text-text/50;
}

.dataTables_paginate {
  @apply -my-2;
}

.dataTables_paginate,
.dataTables_paginate > ul.pagination,
.dataTables_paginate > span {
  @apply button-group gap-x-2;

  > .paginate_button {
    @apply button;
    @apply block py-1 px-3 text-base leading-6;
    @apply bg-transparent text-button-secondary-bg;
    @apply ring-1 ring-button-secondary-bg ring-inset;

    &:hover,
    &:focus {
      @apply bg-button-secondary-bg/10 text-button-secondary-bg;
    }

    &.current {
      @apply bg-button-secondary-bg text-button-secondary-text;

      &:hover,
      &:focus {
        @apply bg-button-secondary-bg-hover text-button-secondary-text;
      }
    }
  }
}

/* DataTable processing indicator. */
.dataTables_processing {
  @apply absolute left-0 bottom-0 w-full h-full bg-bg/50 pointer-events-none;

  .loading-indicator {
    @apply absolute left-0 top-0 w-0 h-0.5 z-10 bg-text;
    animation: 2000ms 500ms theme("transitionTimingFunction.in-out-cubic") infinite dataTables-processing-indicator;
  }
}

@keyframes dataTables-processing-indicator {
  0% {
    left: 0;
    width: 0;
  }
  25%,
  50% {
    left: 0;
    width: 100%;
  }
  75%,
  100% {
    left: 100%;
    width: 0;
  }
}

/* Styles for scrolling dataTables. */
.dataTables_scrollBody {
  table.dataTable thead {
    @apply border-b-0;
  }
}

/* Helper class for hiding the dataTable on page load, before the JS initialization kicks in. */
.dataTables_preloader {
  &:not(.loaded) {
    @apply panel bg-bg-muted;

    table,
    .dataTables_wrapper {
      @apply invisible;
    }
  }

  &.loaded {
    table,
    .dataTables_wrapper {
      @apply visible;
    }
  }
}

/* Override layout for in-panel data tables. */
.panel-body .dataTables_wrapper {
  @apply border-none -m-4;
}

.panel-body > .dataTable {
  @apply -m-4;
  width: calc(100% + 2rem);
}

.panel-body .dataTables_preloader {
  &:not(.loaded) {
    @apply invisible;
  }
}

/* Global table column width classes. */
.dataTable {
  .table-date {
    @apply min-w-32;
  }

  .table-status {
    @apply w-64;
  }

  /* Force table with changes (old vs new) to be the same width. */
  .table-change {
    @apply w-[35%] min-w-72;
  }

  .table-product-name {
    @apply min-w-72;
  }

  .table-lbb-7day {
    @apply min-w-32 max-w-32;
  }

  .table-textlike {
    @apply min-w-64;
  }

  .table-metric-asin {
    @apply w-max;
  }

  .table-metric-right {
    @apply text-right whitespace-nowrap ordinal slashed-zero tabular-nums;
  }

  /* th.table-review-author {
    width: 75px;
  }

  th.table-review-title {
    width: 150px;
  } */
}

.product-table {
  &__asin {
    min-width: 100px;
  }

  &__shipped_cogs {
    min-width: 112px;
  }

  &__source {
    min-width: 110px;
  }

  &__as-of-date {
    min-width: 91px;
  }

  &__attribute {
    min-width: 145px;
  }

  &__replenishment-code {
    min-width: 190px;
  }
}
