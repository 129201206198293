@layer components {
  ul.pagination {
    @apply flex gap-2;

    li {
      @apply contents;

      a {
        @apply block py-1.5 px-3 bg-bg text-text/50 font-medium rounded transition;

        &[href] {
          @apply text-text border border-border;

          &:hover,
          &:focus {
            @apply bg-accent/10 text-accent border-accent;
          }
        }
      }

      &.active a {
        @apply bg-text text-bg border border-text;
      }
    }
  }
}
