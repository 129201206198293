@layer components {
  [data-show-more] {
    @apply relative overflow-hidden;
    max-height: var(--show-more-height, auto);

    &[data-enabled]::before {
      --g-0: rgb(var(--show-more-overlay, var(--theme-bg)) / 1);
      --g-75: rgb(var(--show-more-overlay, var(--theme-bg)) / 0.75);
      --g-100: rgb(var(--show-more-overlay, var(--theme-bg)) / 0);
      content: "";
      @apply absolute left-0 -bottom-px right-0 h-16 pointer-events-none transition;
      @apply bg-gradient-to-t from-[--g-0] via-[--g-75] to-[--g-100];
    }

    &[data-open]::before {
      @apply hidden;
    }

    &:not([data-open]):hover::before {
      @apply opacity-75;
    }

    &:not([data-open]):has(.show-more-toggle:hover)::before {
      @apply opacity-0;
    }

    .show-more-toggle {
      @apply absolute left-2 bottom-4 button py-0.5 px-2 text-sm;

      @apply bg-bg text-text;
      @apply ring-1 ring-text ring-inset;

      &:hover,
      &:focus {
        @apply bg-text text-bg;
      }
    }

    &[data-open] .show-more-toggle {
      @apply relative left-auto bottom-auto my-4 px-2;

      &:hover,
      &:focus {
        @apply bg-text/5 text-text;
      }
    }

    .show-more-border {
      @apply absolute left-0 bottom-0 right-0 border-t border-text opacity-0 transition-all pointer-events-none;
    }

    &:not([data-enabled]) .show-more-border {
      display: none;
    }

    &:not([data-open]):hover .show-more-border {
      @apply opacity-25;
    }

    .show-more-toggle:hover + .show-more-border,
    .show-more-toggle:focus + .show-more-border {
      @apply opacity-100;
    }

    &[data-open] .show-more-toggle:hover + .show-more-border,
    &[data-open] .show-more-toggle:focus + .show-more-border {
      @apply opacity-25;
    }
  }
}
