@layer components {
  .tabs-container {
    @apply border-b border-border;

    ui-tabs,
    .tabs {
      @apply border-none;
    }

    &.sticky {
      @apply z-10 bg-bg/90 backdrop-blur;
      top: calc(var(--header-primary-height) + var(--header-secondary-height));
    }
  }

  ui-tabs,
  .tabs {
    @apply flex -mx-4;
    @apply border-b border-border;
  }

  ui-tabs > ui-tab,
  ul.tabs > li {
  }

  ui-tab,
  .tab-item {
    @apply relative block p-4;

    > a {
      @apply block -m-4 p-4;
    }

    &::before {
      content: "";
      @apply absolute left-4 right-4 -bottom-px border-b border-transparent;
    }

    .badge {
      @apply inline-block ml-2 py-0.5 px-2 bg-accent-light/25 text-xs/none font-semibold text-text/50 rounded-xl align-middle;

      &:empty {
        @apply hidden;
      }
    }

    &:hover,
    &:focus {
      @apply text-accent cursor-pointer;

      &::before {
        @apply border-accent;
      }

      .badge {
        @apply bg-accent-light/50 text-accent;
      }
    }
  }

  ui-tab[active],
  .active > .tab-item,
  .tab-item.active {
    @apply text-accent font-semibold;

    &::before {
      @apply border-b-2 border-accent;
    }

    .badge {
      @apply bg-accent text-bg;
    }
  }

  ui-tab-content,
  .tab-pane {
    @apply hidden;

    &[active],
    &.active {
      @apply block;
    }
  }

  /**
   * Add tab styLe variants.
   */

  /* allow hiding divider */

  ui-tabs.no-divider,
  .tabs.no-divider {
    @apply border-none;
  }

  /* add alternate pill style */

  ui-tabs.tabs-pill,
  .tabs.tabs-pill {
    @apply gap-2;

    ui-tab,
    .tab-item {
      @apply py-2 px-4 rounded-full my-1;
      @apply border border-border;

      &::before {
        display: none;
      }
    }

    ui-tab[active],
    .active > .tab-item,
    .tab-item.active {
      @apply border-accent text-accent;
    }
  }
}
