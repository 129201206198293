@layer components {
  .info-strip {
    @apply flex flex-col gap-1;

    > .value {
      @apply flex gap-2 items-center font-medium text-lg/none transition-all;

      button.info-strip-copy {
        @apply relative inline-block opacity-50;

        &:hover,
        &:focus {
          @apply opacity-100;
        }

        svg {
          width: 0.8em;
          height: 0.8em;
        }
      }

      &.copied {
        animation: info-strip-copied 500ms theme("transitionTimingFunction.in-out-cubic") forwards;

        button.info-strip-copy {
          animation: info-strip-copied-button 500ms theme("transitionTimingFunction.in-out-cubic") forwards;
        }
      }
    }

    > .label {
      @apply font-medium text-text/50;
    }
  }

  @keyframes info-strip-copied {
    0% {
      @apply text-text;
    }

    25% {
      @apply text-status-success-dark;
    }

    100% {
      @apply text-text;
    }
  }

  @keyframes info-strip-copied-button {
    0% {
      transform: scale(1);
      opacity: 1;
    }

    25% {
      transform: scale(1.25);
      opacity: 1;
    }

    100% {
      transform: scale(0.5);
      opacity: 0;
    }
  }
}
