@layer components {
  .dropdown {
    &[data-dropdown-filled] {
      input::placeholder {
        @apply text-text opacity-100;
      }
    }
  }

  .dropdown[data-dropdown] {
    select {
      @apply absolute opacity-0 -left-0 max-h-0 overflow-hidden pointer-events-none;
    }
  }

  .dropdown-popup {
    @apply w-max absolute top-0 left-0 py-2 bg-bg border border-border/50 shadow-lg overflow-y-auto rounded-lg;
    max-height: 50vh;
    z-index: 1050;

    &[data-hide] {
      @apply hidden;
    }
  }

  .dropdown-controls {
    @apply py-2 -mt-2 mb-2 px-4 bg-bg-muted;
    --theme-button-secondary-bg: var(--theme-gray-500);

    .button-group {
      @apply gap-2;
    }

    button {
      @apply text-xs/6 font-semibold py-0 px-3 rounded-2xl;
    }
  }

  .dropdown-options {
    @apply block w-full;

    .optgroup-label {
      @apply pt-2 pb-1 px-4 text-xs font-semibold opacity-50;
    }

    .dropdown-options-empty {
      @apply py-2 px-4 opacity-50;
    }

    button.dropdown-option {
      @apply block py-2 px-4 w-full text-left;

      > * {
        @apply pointer-events-none;
      }

      &.selected {
        @apply font-semibold bg-focus/10;
      }

      &:disabled {
        @apply opacity-50 pointer-events-none;
      }

      &:hover,
      &:focus,
      &.keyboard-focused {
        @apply bg-focus/20;
      }
    }
  }
}
