@layer components {
  html:has(dialog[open]) {
    overflow: hidden;
  }

  dialog[data-dialog] {
    @apply p-0 max-h-[80dvb] bg-bg rounded-lg shadow-xl opacity-0;
    @apply transition-all duration-500 ease-out-quint;

    &.open {
      @apply opacity-100 max-h-[90dvb];
    }

    &::backdrop {
      @apply transition;
      background: rgb(0 0 0 / 0%);
    }

    &.open::backdrop {
      background: rgb(0 0 0 / 50%);
    }

    header {
      @apply py-4 px-8 bg-bg-muted border-b border-border sticky top-0 z-10;
      @apply font-semibold text-text/50;

      .close {
        @apply absolute top-2.5 right-2.5 w-8 h-8 flex items-center justify-center text-xl rounded-full transition;

        &:hover,
        &:focus {
          @apply bg-text text-bg;
        }
      }
    }

    footer {
      @apply py-3 px-8 bg-bg-muted border-t border-border sticky bottom-0;

      > .button-group {
        @apply justify-end -mx-4;
      }
    }
  }
}
