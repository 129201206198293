@layer components {
  .masthead {
    --masthead-bg-color: #f6fffc;
    --masthead-offset: 0;
    @apply relative py-px border-b border-border;
    padding-bottom: var(--masthead-offset);
    margin-bottom: calc(-1 * var(--masthead-offset));

    &::before {
      content: "";
      @apply absolute left-0 top-0 right-0 bottom-0 -z-10 pointer-events-none;
      background: var(--masthead-bg-color);
    }

    &.masthead-fade {
      @apply border-b-0;

      &::after {
        content: "";
        @apply absolute left-0 top-0 right-0 bottom-0 -z-10 pointer-events-none;
        @apply bg-gradient-to-b from-bg/0 to-bg;
      }
    }
  }

  .masthead-bg {
    @apply absolute top-0 w-full h-full -z-10 pointer-events-none;
    @apply left-[50%] max-w-screen-5xl transform translate-x-[-50%];

    &::before {
      content: "";
      @apply hidden 5xl:block absolute left-0 top-0 right-0 bottom-0;
      background: radial-gradient(circle at bottom, transparent 80%, var(--masthead-bg-color) 100%);
    }

    svg {
      @apply w-full h-full object-cover;
    }
  }

  /* masthead subcomponents */
  .masthead {
    .nav-crumbs {
      @apply mt-12 mb-4 flex flex-wrap gap-4 items-center;

      + h1.page-title {
        @apply mt-0;
      }

      a.nav-segment {
        @apply flex gap-2 items-center;
        @apply text-sm uppercase font-semibold text-text/50;

        &:hover,
        &:focus {
          @apply text-accent;
        }

        .icon svg {
          @apply w-4 h-4;
        }
      }
    }
  }
}
