@layer components {
  /**
   * Add modal styling extensions beyond the legacy bootstrap styles.
   * These should all be removed & replaced when we get the chance to deprecate bootstrap completely from IL.
   */
  .modal {
    &.loading .modal-dialog {
      &::before {
        content: "";
        @apply absolute left-0 top-0 right-0 bottom-0 rounded bg-bg/50 z-50;
      }
    }
  }
}
