@layer components {
  .button-group {
    @apply flex flex-wrap items-center gap-x-4 gap-y-2;

    &.right {
      @apply justify-end;
    }

    &.stacked {
      @apply gap-0 flex-nowrap whitespace-nowrap;

      .button:focus {
        @apply z-10;
      }

      .button {
        @apply rounded-none;
      }

      .button + .button {
        @apply -ml-px;
      }

      .button:first-child {
        @apply rounded-l;
      }

      .button:last-child {
        @apply rounded-r;
      }
    }
  }

  .button {
    @apply flex gap-3 items-center;
    @apply py-2 px-4 leading-6 bg-button-bg text-button-text font-medium rounded cursor-pointer transition;
    @apply outline outline-1 outline-offset-4 outline-transparent;

    &:hover,
    &:focus {
      @apply bg-button-bg-hover text-button-text;
    }

    &:focus-visible {
      @apply outline outline-2 outline-offset-2 outline-focus;
    }

    &:disabled {
      @apply filter grayscale opacity-75 pointer-events-none;
    }

    /* Style inline button icons. */
    svg,
    .icon {
      @apply block h-[1.5rem] w-[1em];
    }

    /* Set up button sizes. */
    &.large {
      @apply py-3 px-6 text-xl leading-6;
    }

    &.small {
      @apply py-1 px-4 text-base leading-6;
    }

    &.compact {
      @apply px-2;
    }

    /* Set up button themes. */
    &.secondary {
      @apply bg-button-secondary-bg text-button-secondary-text;

      &:hover,
      &:focus {
        @apply bg-button-secondary-bg-hover text-button-secondary-text;
      }
    }

    &.bordered {
      @apply bg-transparent text-button-bg;
      @apply ring-1 ring-button-bg ring-inset;

      &:hover,
      &:focus {
        @apply bg-button-bg/10;
      }

      &.secondary {
        @apply text-button-secondary-bg;
        @apply ring-1 ring-button-secondary-bg ring-inset;

        &:hover,
        &:focus {
          @apply bg-button-secondary-bg/10;
        }
      }
    }

    &.danger {
      @apply bg-button-danger-bg text-button-danger-text;

      &:hover,
      &:focus {
        @apply bg-button-danger-bg-hover text-button-danger-text;
      }
    }

    &.bordered {
      @apply bg-transparent text-button-bg;
      @apply ring-1 ring-button-bg ring-inset;

      &:hover,
      &:focus {
        @apply bg-button-bg/10;
      }

      &.danger {
        @apply text-button-danger-bg;
        @apply ring-1 ring-button-danger-bg ring-inset;

        &:hover,
        &:focus {
          @apply bg-button-danger-bg/10;
        }
      }
    }
  }
}
