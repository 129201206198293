@layer components {
  .filter-row {
    @apply mb-8 bg-gradient-to-b from-bg-muted to-bg;

    .admin-container {
      @apply relative pt-4 pb-6;

      &::before {
        content: "";
        @apply absolute bottom-0 left-8 right-8 xl:left-16 xl:right-16;
        @apply border-b border-border;
      }
    }
  }
}
