@layer components {
  .product-placeholder {
    @apply relative;

    .product-placeholder__background {
      @apply opacity-50 mx-auto;
    }

    .product-placeholder__message {
      @apply absolute py-6 px-8 w-full max-w-128 text-sm text-text bg-bg rounded border border-border;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
