@layer components {
  .amount {
    @apply inline-flex items-center whitespace-nowrap;
    @apply ordinal slashed-zero tabular-nums;
    text-decoration-line: inherit;

    &.full-width {
      @apply flex gap-1 justify-between;

      .symbol {
        @apply opacity-50;
      }
    }

    &.bad {
      @apply font-medium text-status-critical;
    }

    &.good {
      @apply font-medium text-status-success;
    }
  }
}
