aside.subnav {
  @apply bg-bg-muted border-b border-border;

  &.secondary {
    @apply bg-bg;
  }

  .region-icon {
    @apply w-8 h-8;
  }

  @screen lg {
    @apply w-64 min-w-64 border-b-0 border-r;

    .region-icon {
      @apply w-6 h-6;
    }
  }

  .subnav-list {
    @apply py-4;
  }

  .subnav-group {
    @apply border-t border-b border-border;

    .accordion-content.accordion-open {
      @apply border-t border-border;
    }
  }

  .subnav-item {
    @apply relative block w-full;

    a {
      @apply flex gap-4 items-center py-3 px-8 lg:px-6 transition;
      @apply hover:bg-bg;

      svg {
        @apply -ml-4 lg:-ml-2;
      }
    }

    &.active {
      @apply font-medium bg-accent/5;

      &::after {
        content: "";
        @apply absolute top-2 bottom-2 right-0 w-1 bg-accent rounded-l;
      }
    }

    &.passive.active {
      @apply font-medium bg-bg;

      &::after {
        @apply hidden;
      }
    }
  }

  &.secondary .subnav-item {
    a {
      @apply hover:bg-bg-muted;
    }
  }
}
