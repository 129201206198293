@layer components {
  .progress {
    @apply relative w-full h-3 bg-bg-muted rounded overflow-hidden;
  }

  .progress-bar {
    @apply absolute left-0 top-0 w-0 h-full bg-accent rounded-lg transition-all;
    border: 0.25rem solid theme("colors.bg-muted");

    &.progress-transparent {
      @apply bg-transparent;
    }

    &.progress-accent-2 {
      @apply bg-accent-2;
    }
  }
}
