@layer components {
  #header-secondary {
    @apply bg-bg-muted/90 backdrop-blur border-b border-b-border z-20 sticky top-[--header-primary-height];

    .segments {
      @apply py-2 px-4 flex gap-2 items-center;
    }

    .divider {
      @apply opacity-50;

      svg {
        @apply w-4 h-4;
      }
    }

    .nav-segment .nav-link {
      @apply block py-2 px-4 leading-normal font-semibold rounded transition;
      @apply outline outline-1 outline-offset-4 outline-transparent;

      &:hover,
      &:focus {
        @apply bg-accent/10;
      }

      &:focus-visible {
        @apply outline outline-2 outline-offset-2 outline-focus;
      }
    }

    /* hide the nav dropdown before the JS script takes over it */
    .form-control.dropdown[data-dropdown]:not([data-dropdown-filled]) {
      opacity: 0.01;
    }
  }
}
