@layer components {
  .panel {
    @apply relative -mx-4 bg-bg border border-border rounded-lg overflow-hidden z-0;

    &.highlight {
      @apply border-accent/50 ring-2 ring-accent/10;

      .panel-cta-link .subtitle {
        @apply text-accent;
      }
    }
  }

  .panel {
    > .panel-header,
    > .panel-body,
    > .panel-footer {
      + .panel-header,
      + .panel-body,
      + .panel-footer {
        @apply border-t border-border;
      }
    }
  }

  .panel-header {
    @apply bg-bg-muted p-4 flex flex-wrap gap-4 items-center justify-between;

    .panel-title {
      @apply -my-2 font-semibold;
    }
  }

  .panel-body {
    @apply p-4;
  }

  .panel-footer {
    @apply bg-bg-muted p-4;
  }

  .panel-bg {
    @apply absolute left-0 right-0 top-0 bottom-0 -z-10 pointer-events-none;

    img,
    svg {
      @apply w-full h-full object-cover;
    }
  }

  .panel-cta-link {
    @apply px-4 py-2 flex gap-4 items-center justify-between transition;

    &:hover,
    &:focus {
      @apply text-accent;
    }

    .content {
      @apply flex flex-col items-start;
    }

    .title {
      @apply text-lg font-medium;
    }

    .subtitle {
      @apply text-sm font-medium text-text/50;
    }

    .icon {
      svg {
        @apply w-5 h-5;
      }
    }
  }

  /* toggle-buttons in panels */
  .panel-header .panel-actions .toggle-buttons {
    @apply -my-2.5;

    .toggle-button {
      @apply text-xs font-medium py-1 px-2;
    }
  }

  /* date picker control in panels */
  .panel-header .panel-actions .inline-datepicker {
    @apply -my-2.5 flex gap-2 items-center;

    label {
      @apply text-sm font-medium text-text/50;
    }

    input {
      @apply bg-bg text-sm font-medium py-0.5 px-2 rounded;
      @apply outline outline-1 outline-offset-4 outline-transparent ring-1 ring-border ring-inset;

      &:focus {
        @apply outline outline-2 outline-offset-2 outline-focus;
      }
    }
  }
}
