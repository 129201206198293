@layer components {
  .tiptap-editor {
    @apply relative rounded border border-border;

    &.prose .tiptap {
      > :first-child,
      > :first-child > :first-child,
      > :first-child > :first-child > :first-child {
        @apply !mt-0;
      }

      > :last-child,
      > :last-child > :last-child,
      > :last-child > :last-child > :last-child {
        @apply !mb-0;
      }
    }

    .tiptap {
      @apply block w-full pt-10 pb-3 px-4 leading-6 bg-bg border-none rounded appearance-none transition;
      @apply outline outline-1 outline-offset-4 outline-transparent;
      @apply max-h-[75vh] overflow-auto;

      &:disabled {
        @apply bg-bg-muted;
      }

      &:hover:not(:disabled) {
      }

      &:focus:not(:disabled) {
        @apply outline outline-2 outline-offset-2 outline-focus;
      }

      &::placeholder {
        @apply text-text opacity-50;
      }

      .ProseMirror-selectednode {
        @apply outline outline-1 outline-offset-2 outline-focus;
      }
    }
  }

  .tiptap-toolbar {
    @apply absolute left-2 top-2 right-2 z-10 transform translate-y-0 transition;
    @apply flex flex-wrap gap-2 items-center;

    &:not([open]) {
      @apply translate-y-2 opacity-0 pointer-events-none;
    }

    .toolbar-button {
      @apply py-1 px-2 text-xs font-semibold uppercase border border-border rounded transition;
      @apply bg-bg/50 hover:bg-bg/90 backdrop-blur;

      &:hover:not(:disabled) {
        @apply bg-border/25;
      }

      &:active:not(:disabled) {
        @apply bg-border/75;
      }

      &.pressed {
        @apply bg-text/50 text-bg border-text/25;

        &:hover:not(:disabled) {
          @apply bg-text/75;
        }

        &:active:not(:disabled) {
          @apply bg-text/90;
        }
      }
    }

    .toolbar-divider {
      @apply w-px h-4 border-r border-border;
    }
  }
}
