@layer tw-base, legacy, tw-components, tw-utilities;

/* base styles */
@import "stylesheets/base/variables";
@import "stylesheets/base/layout";
@import "stylesheets/base/grid";
@import "stylesheets/base/typography";

/* component styles */
@import "stylesheets/components/header-primary";
@import "stylesheets/components/header-secondary";
@import "stylesheets/components/sidebar";
@import "stylesheets/components/button";
@import "stylesheets/components/toggle-buttons";
@import "stylesheets/components/form";
@import "stylesheets/components/file-dropper";
@import "stylesheets/components/accordion";
@import "stylesheets/components/dropdown";
@import "stylesheets/components/dialog";
@import "stylesheets/components/tabs";
@import "stylesheets/components/alert";
@import "stylesheets/components/pagination";
@import "stylesheets/components/panel";
@import "stylesheets/components/masthead";
@import "stylesheets/components/metric-cards";
@import "stylesheets/components/metric-change";
@import "stylesheets/components/modal";
@import "stylesheets/components/region-icon";
@import "stylesheets/components/filter-row";
@import "stylesheets/components/amount";
@import "stylesheets/components/tags";
@import "stylesheets/components/product-placeholder";
@import "stylesheets/components/info-strip";
@import "stylesheets/components/show-more";
@import "stylesheets/components/progress-bar";
@import "stylesheets/components/status-badge";
@import "stylesheets/components/reports";
@import "stylesheets/components/tiptap";
@import "stylesheets/components/searchable";

/* vendor-specific styles */
@import "stylesheets/amazon/aplus-module";

/* dash styles */
@import "stylesheets/dash/dash";

/* legacy styles */
@import "stylesheets/legacy/bootstrap" layer(legacy);
@import "stylesheets/legacy/data-table" layer(legacy);
@import "stylesheets/legacy/datepicker" layer(legacy);
@import "stylesheets/legacy/daterangepicker" layer(legacy);
@import "stylesheets/legacy/diff" layer(legacy);
@import "stylesheets/legacy/show-more" layer(legacy);

@layer tw-base {
  @tailwind base;
}
@layer tw-components {
  @tailwind components;
}
@layer tw-utilities {
  @tailwind utilities;
}
