@layer components {
  .region-icon {
    @apply block w-4 h-4;
    @apply flex-shrink-0;

    img {
      @apply block w-full h-full object-contain;
    }
  }
}
