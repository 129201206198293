@layer components {
  .accordion {
    display: block;
  }

  .accordion-toggle {
    @apply flex items-center justify-between cursor-pointer;

    .accordion-icon {
      @apply transition-all;
    }
  }

  [data-accordion-open="true"] > .accordion-toggle .accordion-icon,
  [data-accordion-open="true"] > * > .accordion-toggle .accordion-icon {
    transform: rotateZ(-180deg);
  }

  .accordion-content {
    @apply relative max-h-0 overflow-hidden;
    transition: max-height 500ms cubic-bezier(0.1, 0.87, 0.3, 0.99);
  }

  .accordion.no-transition .accordion-content {
    transition: none !important;
  }
}
