#dash-content {
  h1 {
    @apply mt-8 text-4xl font-bold text-accent-dark;
  }

  a.dash-title-back {
    @apply mt-8 -ml-6 inline-flex gap-2 items-center text-lg font-medium text-accent underline;

    &:hover,
    &:focus {
      @apply text-accent-dark;
    }

    &:focus-visible {
      @apply text-accent;
      @apply outline outline-4 outline-offset-0 outline-focus/10 bg-focus/10;
    }

    svg {
      @apply block w-4 h-4;
    }

    + h1 {
      @apply mt-2;
    }
  }

  h2 {
    @apply mt-8 text-2xl text-accent;
  }

  .panel {
    @apply my-8;
  }

  table {
    @apply min-w-full;

    /* apply default striping styles */
    tbody tr:nth-child(2n) {
      @apply bg-bg-muted/50;
    }

    /* apply default border colors everywhere */
    thead,
    tbody,
    tfoot,
    tr,
    td,
    th {
      @apply border-border transition;
    }

    th,
    td {
      @apply text-left py-3 px-4;
    }

    th:last-child.border-r,
    td:last-child.border-r {
      @apply !border-r-0;
    }

    thead {
      @apply bg-bg-muted border-b;
    }

    /* generic table styles */
    thead th {
      @apply align-bottom font-semibold text-text/50;

      strong {
        @apply font-semibold text-text;
      }
    }

    tbody {
      @apply relative bg-bg;
    }

    tbody td {
      @apply align-top;
    }

    tbody + tbody tr:first-child {
      @apply border-t;
    }

    tbody tr:not(:last-child) {
      @apply border-b border-border/50;
    }

    tfoot {
      @apply bg-bg-muted border-t;
    }

    tfoot ul.pagination {
      @apply float-right;
    }
  }

  table.dash-actions {
    td {
      @apply bg-bg-muted;
    }

    td:first-child {
      @apply bg-bg w-128 border-r border-border/50;
    }

    form {
      @apply grid gap-4;
    }
  }

  .dash-stats {
    @apply my-8 flex flex-wrap gap-x-16 gap-y-4;

    .stat {
      @apply flex flex-col;
    }

    .value {
      @apply text-3xl text-accent;
    }

    .label {
      @apply font-semibold;
    }

    &.small {
      .value {
        @apply text-xl text-text font-medium;
      }

      .label {
        @apply text-text/80;
      }
    }
  }
}

/* primitive fix for stupid hardcoded bootbox button classes */
.bootbox {
  .modal-footer {
    @apply flex gap-4 justify-end;
  }

  .btn {
    @apply button;
  }
}

.status-text {
  @apply text-text/50;

  &.success,
  &.completed {
    @apply text-status-success;
  }

  &.failed {
    @apply text-status-critical;
  }

  &.waiting,
  &.paused,
  &.not_found {
    @apply text-text/80;
  }

  &.importing,
  &.to_finalize,
  &.finalizing {
    @apply text-status-info/80;
  }

  &.imported {
    @apply text-status-info;
  }
}

.tooltip-icon {
  @apply opacity-25 cursor-help hover:text-accent hover:opacity-100;
  font-size: 0.8em;

  &::before {
    content: "🞲";
  }
}
