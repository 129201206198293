@layer components {
  .status-badge {
    @apply block w-max py-0.5 px-2 bg-text/10 text-text/50 text-xs font-semibold rounded-2xl uppercase whitespace-nowrap;

    /* WxCrawl status colors */
    &.success,
    &.completed {
      @apply bg-status-success text-bg;
    }

    &.failed {
      @apply bg-status-critical text-bg;
    }

    &.waiting,
    &.paused,
    &.not_found {
      @apply bg-text/50 text-bg;
    }

    &.importing,
    &.finalizing {
      @apply bg-status-info/10 text-status-info;
    }

    &.imported {
      @apply bg-status-info text-bg;
    }

    /* Purchase Order status colors */
    &.open {
      @apply bg-status-success/10 text-status-success;
    }

    &.confirmed {
      @apply bg-status-success text-bg;
    }

    &.closed {
      @apply bg-text/10 text-text/50;
    }

    &.pending_cancellation {
      @apply bg-status-critical/10 text-status-critical;
    }

    &.cancelled {
      @apply bg-status-critical text-bg;
    }
  }
}
