@layer components {
  .amazon-aplus-module {
    /* general text styles */
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      @apply my-0 font-medium text-text;
    }

    h1 {
      @apply text-xl;
    }

    h2,
    h3 {
      @apply text-lg;
    }

    h4,
    h5,
    h6 {
      @apply text-base;
    }

    p {
      @apply mt-0 mb-4;
    }

    ol > li,
    ul > li {
      @apply my-1;
    }

    .a-size-small {
      @apply text-sm;
    }

    .a-text-bold {
      @apply font-semibold;
    }

    .a-text-italic {
      @apply italic;
    }

    .a-color-secondary,
    .a-color-tertiary {
      @apply text-text/75;
    }

    img {
      @apply border-none;
    }

    /* general container styles */
    .aplus-module {
      @apply clear-both;
    }

    .a-spacing-base,
    .a-ws-spacing-base {
      @apply mb-4;
    }

    .a-spacing-small {
      @apply mb-3;
    }

    .a-spacing-mini {
      @apply mb-2;
    }

    .aplus-standard.module-1,
    .aplus-standard.module-2,
    .aplus-standard.module-3,
    .aplus-standard.module-4,
    .aplus-standard.module-6,
    .aplus-standard.module-7,
    .aplus-standard.module-8,
    .aplus-standard.module-9,
    .aplus-standard.module-10,
    .aplus-standard.module-11,
    .aplus-standard.module-12 {
      @apply mb-4;
    }

    .aplus-container-1 {
      @apply p-4;
    }

    .a-box {
      @apply border border-border rounded;

      .a-box-inner {
        @apply py-3.5 px-4;
      }
    }

    .a-color-alternate-background {
      @apply bg-bg-muted;
    }

    /* float layouts */
    .apm-wrap {
      @apply w-full;
    }

    .apm-floatleft {
      @apply float-left;
    }

    .apm-lefttwothirdswrap {
      @apply w-[calc(75%-2rem)];

      .apm-leftimage {
        @apply w-[calc(33%-2rem)];
      }
    }

    .apm-lefthalfcol {
      @apply float-left mr-8 w-[calc(50%-2rem)];
    }

    .apm-righthalfcol {
      @apply float-left w-[calc(50%-2rem)];
    }

    .apm-leftimage {
      @apply float-left mr-8 w-[calc(25%-2rem)];
    }

    .apm-centerthirdcol {
    }

    .amp-centerthirdcol-listbox {
      @apply inline-block w-[calc(50%-4rem)];

      .apm-listbox {
        @apply w-full;
      }
    }

    .apm-rightthirdcol {
      @apply float-right ml-8 w-[calc(25%-2rem)];
    }

    .apm-sidemodule {
      @apply flex gap-8 items-center;

      .apm-sidemodule-imageleft,
      .apm-sidemodule-imageright {
        flex: 0 0 25%;
      }

      .apm-sidemodule-textleft,
      .apm-sidemodule-imageleft {
        order: 0;
      }

      .apm-sidemodule-imageright,
      .apm-sidemodule-textright {
        order: 1;
      }
    }

    /* general table styles */
    .table-container {
      @apply overflow-x-auto;
    }

    table {
      @apply my-0 outline-none shadow-none;
    }

    table.apm-fixed-width {
      @apply table-fixed;
    }

    table tr {
      @apply bg-transparent border-0;
    }

    table td,
    table th {
      @apply py-0 px-2 align-top bg-transparent;
    }

    /* comparison table module */
    table.apm-tablemodule-table {
      td,
      th {
        @apply py-2;
      }

      tr.apm-tablemodule-imagerows th {
        @apply bg-transparent align-bottom;
      }

      tr.apm-tablemodule-keyvalue td {
        @apply border border-x-0 border-border;
      }

      td.apm-tablemodule-valuecell {
        @apply bg-transparent;
      }

      th.apm-tablemodule-keyhead {
        @apply bg-bg-muted border border-l-0 border-border;
      }
    }

    /* basic carousel styling (fallback to a grid layout) */
    .a-carousel-viewport {
      @apply !h-auto;
    }

    ol.a-carousel {
      @apply !w-auto my-4 p-0 list-none grid sm:grid-cols-2 md:grid-cols-3 gap-4;

      > li.a-carousel-card {
        @apply !m-0 p-0 !w-auto !visible;
      }
    }

    .a-carousel-left:has(.a-carousel-goto-prevpage),
    .a-carousel-right:has(.a-carousel-goto-nextpage),
    .a-carousel-goto-prevpage,
    .a-carousel-goto-nextpage,
    .aplus-pagination-wrapper {
      @apply hidden;
    }

    /* brand story cards (carousel -> viewport -> cards) */
    .apm-brand-story-card {
      @apply w-full max-w-80;

      > .apm-brand-story-image-row {
        @apply w-full flex gap-2 mb-2;
      }
    }

    /* remove gray pixel images */
    img[src$="grey-pixel.gif"] {
      @apply hidden;
    }

    /* style hero video players */
    .video-container .video-js {
      @apply relative;

      .vjs-control-text {
        @apply py-3 px-4 bg-text/50 text-bg font-medium rounded-full backdrop-blur cursor-pointer;
        @apply absolute left-[50%] top-[50%];
        @apply transform translate-x-[-50%] translate-y-[-50%];
      }
    }

    /* -- custom module-specific overrides -- */

    /* standard padded text A+ module */
    .aplus-module-13 {
      @apply my-8;
    }

    .launchpad-module {
      @apply my-8;
    }

    /* A+ standard centered image/logo */
    .launchpad-module-company-logo {
      img {
        @apply mx-auto;
      }
    }

    /* 2-column image+text module */
    .premium-aplus-module-1 {
      .aplus-display-table {
        @apply grid sm:grid-cols-2 items-center;
      }

      .aplus-module-section.aplus-text-section-left,
      .aplus-module-section.aplus-text-section-right {
        @apply px-8;
      }
    }

    /* 4-column image grid */
    .premium-module-3-four-column-images {
      .premium-aplus-four-column {
        @apply grid gap-4 xs:grid-cols-2 sm:grid-cols-4;
      }
    }

    .premium-aplus-module-3 .column-heading {
      @apply pt-4;
    }

    /* regimen carousel */
    .premium-module-12-regimen-carousel {
      .regimen.aplus-carousel-actions {
        @apply flex flex-wrap gap-4 justify-center;

        .aplus-goto-btn {
          @apply block py-1.5 px-4 border border-border rounded-full;

          .aplus-carousel-label {
            @apply font-semibold;
          }
        }
      }
    }

    /* -- aplus comparison diff -- */
    .aplus-diff {
    }

    &.aplus-compare-left ins.aplus-diff,
    &.aplus-compare-right del.aplus-diff {
      @apply hidden;
    }

    del.aplus-diff {
      @apply bg-status-critical/25;

      a:hover,
      a:focus {
        @apply text-status-critical;
      }

      img,
      video {
        @apply outline outline-2 outline-offset-1 outline-status-critical;
      }
    }

    ins.aplus-diff {
      @apply bg-status-success/25;

      a:hover,
      a:focus {
        @apply text-status-success;
      }

      img,
      video {
        @apply outline outline-2 outline-offset-1 outline-status-success;
      }
    }
  }
}
