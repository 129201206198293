@layer base {
  .prose {
    @apply max-w-prose;

    &.noclamp {
      @apply max-w-none;
    }

    > :first-child,
    > :first-child > :first-child,
    > :first-child > :first-child > :first-child {
      @apply mt-0;
    }

    > :last-child,
    > :last-child > :last-child,
    > :last-child > :last-child > :last-child {
      @apply mb-0;
    }

    /* headings */
    h1,
    h2,
    h3,
    h4 {
      @apply mt-[2em] mb-[0.5em] font-normal;
    }

    h1 {
      @apply mt-0 text-4xl font-bold text-accent-dark;
    }

    h2 {
      @apply text-2xl text-accent;
    }

    h3 {
      @apply text-xl font-normal;
    }

    h4 {
      @apply text-lg font-medium;
    }

    h2 + *,
    h3 + *,
    h4 + * {
      @apply mt-0;
    }

    /* paragraph & inline content */
    p {
      @apply my-[1em] leading-normal;
    }

    strong {
      @apply font-semibold;
    }

    a:not(.button) {
      @apply font-medium underline;

      &:hover,
      &:focus {
        @apply text-accent;
      }

      &:focus-visible {
        @apply text-accent;
        @apply outline outline-4 outline-offset-0 outline-focus/10 bg-focus/10;
      }
    }

    /* lists */
    ul,
    ol {
      @apply pl-[1.25em];

      > li {
        @apply my-4 pl-1;

        /* nested lists should have less spacing */
        li {
          @apply my-2;
        }

        &::marker {
          @apply text-sm font-semibold text-current;
        }

        > p {
          @apply my-2;
        }
      }

      /* compact list style variant helper to pack more items in a space */
      &.compact {
        > li {
          @apply my-1;
        }
      }
    }

    ul {
      @apply list-disc;
    }

    ol {
      @apply list-decimal;
    }

    /* blockquotes, code, etc */
    blockquote {
      @apply relative my-8 border-l border-border px-4 text-lg italic text-text-muted md:text-2xl md:font-light;

      &::before {
        content: "“";
        @apply absolute -top-[0.05em] -left-[0.375em] h-[0.67em] bg-bg text-[2.5em] font-normal leading-none;
      }

      &.small {
        @apply my-4 text-base md:text-lg md:font-normal;
      }
    }

    pre {
      @apply overflow-auto rounded bg-bg-muted p-4;
    }

    /* images, figure + figcaption, etc */
    img {
      @apply max-w-full rounded border border-border;
    }

    > figure,
    > img {
      @apply my-8;
    }

    figcaption {
      @apply mt-2 text-xs text-text-muted;
    }

    /* tables */
    table {
      @apply my-8 w-full overflow-hidden rounded shadow;
      --tw-shadow: 0 0 0 1px theme("colors.border");

      th,
      td {
        @apply py-2 px-4 text-left;
      }

      th {
        @apply font-semibold;
      }

      tbody tr:not(:last-child) {
        @apply border-b border-border;
      }

      th + td,
      td + th {
        @apply border-l border-border;
      }

      tr {
        @apply bg-bg even:bg-bg-muted;
      }
    }

    /* other */
    hr {
      @apply my-8 border-border;
    }
  }
}

/* general type helpers */
@layer components {
  .numeric {
    @apply ordinal slashed-zero tabular-nums;
  }

  a.link {
    @apply font-medium underline;

    &:hover,
    &:focus {
      @apply text-accent;
    }

    &:focus-visible {
      @apply text-accent;
      @apply outline outline-4 outline-offset-0 outline-focus/10 bg-focus/10;
    }
  }
}
