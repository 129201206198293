/**
 * Resurrected daterangepicker styles live here. This is a customized stylesheet with look & feel overrides from the
 * original popup styles.
 */

/* Root popup mega-class. This group contains most customizations & new layout definitions. */
.daterangepicker {
  position: absolute;
  color: inherit;
  top: 100px;
  left: 20px;
  z-index: 3001;

  @apply p-0 mt-2 hidden rounded;
  grid-template-areas: "calendar-left" "calendar-right" "ranges";

  /* Weird hack since we're not using display:block on the root popup. */
  &[style*="block"] {
    @apply !grid;
  }

  @screen sm {
    grid-template-areas: "calendar-left ranges" "calendar-right ranges";
  }

  @screen lg {
    grid-template-areas: "calendar-left calendar-right ranges";
  }

  > .calendar {
    &.left {
      grid-area: calendar-left;
    }

    &.right {
      grid-area: calendar-right;
      @apply max-lg:border-t lg:border-l border-border;
    }

    .daterangepicker_input {
      @apply bg-bg-muted border-b border-border;

      input {
        @apply py-1 px-4 w-full text-sm font-medium text-center text-text/50 bg-bg-muted;

        &:focus {
          @apply outline-none ring-1 ring-focus;
        }
      }
    }
  }

  > .ranges {
    grid-area: ranges;
    @apply flex flex-col justify-between bg-bg-muted max-sm:border-t sm:border-l border-border max-sm:rounded-b sm:rounded-r;

    ul {
      @apply my-2;

      li {
        @apply block p-1 px-2 pr-8 text-sm font-medium cursor-pointer;

        &:hover,
        &:focus {
          @apply bg-focus/5;
        }

        &.active {
          @apply bg-focus text-bg;
        }
      }
    }

    .range_inputs {
      @apply py-2 px-2 border-t border-border;
      @apply button-group gap-2;

      > .btn {
        @apply button py-1 px-3 text-sm;
        @apply bg-button-secondary-bg text-button-secondary-text;

        &:hover,
        &:focus {
          @apply bg-button-secondary-bg-hover text-button-secondary-text;
        }
      }

      > .btn-success {
      }

      > .btn-default {
        @apply bg-transparent text-button-secondary-bg;
        @apply ring-1 ring-button-secondary-bg ring-inset;

        &:hover,
        &:focus {
          @apply bg-button-secondary-bg/10 text-button-secondary-bg;
        }
      }
    }
  }
}

/* Everything below is mostly daterangepicker stock CSS, with some conflicting rules removed and/or modified. */
.daterangepicker {
  &::before,
  &::after {
    position: absolute;
    display: inline-block;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    content: "";
  }

  &::before {
    top: -7px;
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
    border-bottom: 7px solid #ccc;
  }

  &::after {
    top: -6px;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #fff;
    border-left: 6px solid transparent;
  }

  &.opensleft::before {
    right: 9px;
  }

  &.opensleft::after {
    right: 10px;
  }

  &.openscenter::before {
    left: 0;
    right: 0;
    width: 0;
    margin-left: auto;
    margin-right: auto;
  }

  &.openscenter::after {
    left: 0;
    right: 0;
    width: 0;
    margin-left: auto;
    margin-right: auto;
  }

  &.opensright::before {
    left: 9px;
  }

  &.opensright::after {
    left: 10px;
  }

  &.drop-up {
    margin-top: -7px;
  }

  &.drop-up::before {
    top: initial;
    bottom: -7px;
    border-bottom: initial;
    border-top: 7px solid #ccc;
  }

  &.drop-up::after {
    top: initial;
    bottom: -6px;
    border-bottom: initial;
    border-top: 6px solid #fff;
  }

  &.single .drp-selected {
    display: none;
  }

  &.show-calendar .calendar {
    display: block;
  }

  &.show-calendar .drp-buttons {
    display: block;
  }

  &.auto-apply .drp-buttons {
    display: none;
  }

  & .calendar {
    display: none;
  }

  /* custom icon style overrides */
  .calendar-table .next,
  .calendar-table .prev {
    @apply bg-center bg-no-repeat;
    background-size: 1rem 1rem;
  }

  .calendar-table .next i,
  .calendar-table .prev i {
    @apply hidden;
  }

  .calendar-table .next {
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 4L17 12L9 20' stroke='black' stroke-linecap='square'/%3E%3C/svg%3E");
  }

  .calendar-table .prev {
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15 20L7 12L15 4' stroke='black' stroke-linecap='square'/%3E%3C/svg%3E");
  }

  .calendar-table .next span,
  .calendar-table .prev span {
    color: #fff;
    border: solid black;
    border-width: 0 2px 2px 0;
    border-radius: 0;
    display: inline-block;
    padding: 3px;
  }

  .calendar-table .next span {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }

  .calendar-table .prev span {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }

  .calendar-table th,
  .calendar-table td {
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;

    @apply w-8 h-7 text-sm;
  }

  .calendar-table table {
    width: 100%;
    margin: 0;
    border-spacing: 0;
    border-collapse: collapse;
  }

  thead tr:first-child th {
    @apply border-b border-b-border/50;
  }

  thead tr:nth-child(2) th {
    @apply text-xs text-text/50 border-b border-b-border/50;
  }

  td.available:hover,
  th.available:hover {
    @apply border-transparent bg-focus/10 text-focus cursor-pointer rounded;
  }

  td.week,
  th.week {
    @apply text-xs bg-bg-muted text-text/25 rounded-none border-r border-r-border/50;
  }

  td.off,
  td.off.in-range,
  td.off.start-date,
  td.off.end-date {
    @apply border-transparent bg-bg text-text/25 !bg-none;
  }

  td.in-range {
    @apply border-transparent bg-focus/5 text-focus font-medium rounded-none;
  }

  td.start-date {
    @apply rounded-l rounded-r-none;
  }

  td.end-date {
    @apply rounded-r rounded-l-none;
  }

  td.start-date.end-date {
    @apply rounded;
  }

  td.active,
  td.active:hover {
    @apply bg-focus/5 text-focus border-transparent;
  }

  td.active.start-date {
    background-image: linear-gradient(to right, theme("colors.focus/25%"), theme("colors.focus/0%"));
    @apply bg-no-repeat;
  }

  td.active.end-date {
    background-image: linear-gradient(to left, theme("colors.focus/25%"), theme("colors.focus/0%"));
    @apply bg-no-repeat;
  }

  td.active.start-date.end-date {
    @apply bg-none bg-focus/25;
  }

  th.month {
    width: auto;
  }

  td.disabled,
  option.disabled {
    cursor: not-allowed;
    text-decoration: line-through;

    @apply text-text/50;
  }

  select.monthselect,
  select.yearselect {
    font-size: 12px;
    padding: 1px;
    height: auto;
    margin: 0;
    cursor: default;
  }

  select.monthselect {
    margin-right: 2%;
    width: 56%;
  }

  select.yearselect {
    width: 40%;
  }

  select.hourselect,
  select.minuteselect,
  select.secondselect,
  select.ampmselect {
    width: 50px;
    margin: 0 auto;
    background: #eee;
    border: 1px solid #eee;
    padding: 2px;
    outline: 0;
    font-size: 12px;
  }

  .calendar-time {
    text-align: center;
    margin: 4px auto 0 auto;
    line-height: 30px;
    position: relative;
  }

  .calendar-time select.disabled {
    color: #ccc;
    cursor: not-allowed;
  }

  .drp-buttons {
    clear: both;
    text-align: right;
    padding: 8px;
    border-top: 1px solid #ddd;
    display: none;
    line-height: 12px;
    vertical-align: middle;
  }

  .drp-selected {
    display: inline-block;
    font-size: 12px;
    padding-right: 8px;
  }

  .drp-buttons .btn {
    margin-left: 8px;
    font-size: 12px;
    font-weight: bold;
    padding: 4px 8px;
  }
}
