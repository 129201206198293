@layer components {
  .tags-container {
    @apply flex flex-wrap gap-1;

    > .tag {
      @apply px-2 py-0.5 rounded font-medium border border-text/25 text-sm;
      @apply flex gap-1.5 items-center;

      .tag-close {
        @apply text-text/50;

        &:hover,
        &:focus {
          @apply text-status-critical;
        }
      }
    }

    > .add-tag {
      @apply px-2 py-0.5 rounded font-medium bg-button-secondary-bg text-button-secondary-text text-sm cursor-pointer;
      @apply flex gap-2 items-center;

      &:hover,
      &:focus {
        @apply bg-button-secondary-bg-hover;
      }
    }
  }

  .product-tags__options-container {
    @apply py-4 flex flex-col;
  }

  .product-tags__add-option {
    @apply py-3 px-8 text-left;

    &:hover,
    &:focus {
      @apply bg-button-bg/5 text-button-bg;
    }
  }

  .product-tags__no-tags {
    @apply py-3 px-8 text-text/50;

    a {
      @apply text-text font-medium;

      &:hover,
      &:focus {
        @apply text-accent underline;
      }
    }
  }
}
